import { NgModule, LOCALE_ID } from '@angular/core';

import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home-routing.module';
import { AdelantoHomeComponent } from './productos/adelanto/adelanto.home';
import { PrestamoHomeComponent } from './productos/prestamo/prestamo.home';
import { HomeMisCreditosComponent } from './productos/miscreditos/home.miscreditos';
import { SharedModule } from '../../shared.module';
import { AngularMaterialModule } from '../angularMaterial/angularMaterial.module';
import { HomeAuthResolver } from '../../../core/services/home/home-auth-resolver.service';
import { registerLocaleData } from '@angular/common';
import localeEsMx from '@angular/common/locales/es-MX';
import { IFramePSComponent } from './productos/prestamo/prestamo.iframe';
import { IFrameADEComponent } from './productos/adelanto/adelanto.iframe';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PaginasComunesModule } from '../../../components/credito/comunes/paginas.comunes.module';
import { ResumenComponent } from 'src/app/components/credito/comunes/6.resumen/resumen.component';
import { TarjetaOroHomeComponent } from './productos/tarjetaoro/tarjetaoro.home';
import { IFrameTORComponent } from './productos/tarjetaoro/tarjetaoro.iframe';
import { DivisasGSHomeComponent } from './productos/divisasgs/divisasgs.home';
import { BeneficioEKTHomeComponent } from './productos/beneficioekt/beneficioekt.home';
import { CreditoBFComponent } from './productos/creditoBf/creditobf.home';
import { TPremiaHomeComponent } from './productos/tpremia/tpremia.home';
import { CrediautoComponent } from './productos/crediauto/crediauto.component';
import { InversionActivaComponent } from './productos/inversion-activa/inversion-activa.component';
import { PortalGuatemalaComponent } from './portal-guatemala/portal-guatemala.component';
import { PrestaPrendaComponent } from './productos/presta-prenda/presta-prenda.component';

registerLocaleData(localeEsMx);

@NgModule({
  imports: [
    SharedModule,
    HomeRoutingModule,
    AngularMaterialModule,
    NgxExtendedPdfViewerModule,
    PaginasComunesModule
  ],
  declarations: [
    IFrameADEComponent,
    IFramePSComponent,
    IFrameTORComponent,
    HomeComponent,
    AdelantoHomeComponent,
    PrestamoHomeComponent,
    TarjetaOroHomeComponent,
    DivisasGSHomeComponent,
    BeneficioEKTHomeComponent,
    HomeMisCreditosComponent,
    CreditoBFComponent,
    TPremiaHomeComponent,
    CrediautoComponent,
    InversionActivaComponent,
    PortalGuatemalaComponent,
    PrestaPrendaComponent

  ],
  providers: [
    HomeAuthResolver,
    { provide: LOCALE_ID, useValue: 'es-MX' }
  ]
})
export class HomeModule {}
