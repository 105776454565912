import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { CancelacionModel } from '../../../../core/models/credito/datos.socios/cancelacion.model';
import { ApiDatosSocioService } from '../../../../core/services/credito/api.datos.socio/api.datos.socio.service';
export interface DialogDataCan {
  message: string;
  opciones: boolean;
  rutaDestino: string;
}
@Component({
  selector: 'app-dialog-overview-msg1',
  template: `<div class="modalSmall">
  <h2 class="cancela">Cancelación</h2>
  <p>Seleccione el motivo de la cancelación:</p>
  <form formDC id="formDC" class="content_form" [formGroup]="datosCancelaciones" (ngSubmit)="cancelar()">
      <div class="col col-10 opciones" *ngIf="opciones">
          <mat-form-field>
              <mat-select [formControl]="datosCancelaciones.controls.cancelacion" placeholder="Cancelaciones"
                  required>
                  <mat-option [value]="item.idCancelacion" *ngFor="let item of cancelaciones;" required>
                      {{item.cancelacion}}
                  </mat-option>
              </mat-select>
          </mat-form-field>
      </div>
      <div class="btns_centrados">
          <button type="button" class="btn all" (click)="cerrarModal()"
              routerLinkActive="router-link-active">Cancelar</button>
          <button type="submit" class="btn all">Ok</button>
      </div>
  </form>
  <div class="footer">&nbsp;</div>
</div>`,
  styleUrls: ['dialog-overview-msg1.css']
})

export class DialogCancelacionComponent implements OnInit {
  cancelaciones = [];
  opciones: boolean;
  datosCancelaciones: any;
  numEmpleado: string;
  producto: string;
  constructor(
    public dialogRef: MatDialogRef<DialogCancelacionComponent>,
    private router: Router,
    private formBuilder: FormBuilder,
    private apiDSCancelacion: ApiDatosSocioService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCan) {
      this.dialogRef.disableClose = true;
    }

    ngOnInit(): void {
      this.opciones = this.data.opciones;
      this.numEmpleado = window.sessionStorage ? CryptoJS.AES.decrypt(sessionStorage.getItem('numeroEmpleado'), 
      environment.contra_crypto).toString(CryptoJS.enc.Utf8) : '';
      this.producto = window.sessionStorage ? sessionStorage.getItem('producto_id') : '';
      this.datosCancelaciones = this.formBuilder.group({
        cancelacion: ['', Validators.required]
      });
      if (this.opciones) {
        this.apiDSCancelacion.getCancelaciones(this.producto).subscribe(cancelaciones => {
          this.cancelaciones = cancelaciones.resultado;
          });
      }
  
  
    }

  cerrarModal(): void {
    this.dialogRef.close(false);
  }

  cancelar() {
    if (this.opciones) {
    const cancelacion = new CancelacionModel(this.numEmpleado, this.datosCancelaciones.controls.cancelacion.value, Number(this.producto));
    this.apiDSCancelacion.postGuardarCancelacion(cancelacion).subscribe(cancel => {
      console.log(cancel);
      this.dialogRef.close(false);
      this.router.navigate([this.data.rutaDestino], { skipLocationChange: false });
    });
  }

  }
}
