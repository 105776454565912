import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  template: `
  <footer>
    <div class="container foot">
      <div class="row">
        <div class="col col-lg-6 alto_footer_one">
          <a href="#" class="facebook">
            <p class="follow">Síguenos en:</p>
          </a>
        </div>
        <div class="col col-lg-6 alto_footer_two">
          <a class="cuentanos">Cuéntanos</a>
          <p class="legal">© 2019 Grupo Salinas   v 0.161.0-1</p>
        </div>
      </div>
    </div>
  </footer>
  `,
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  name = 'Desde componente';
  constructor() { }
}
