import { Component, OnInit, Host, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ApiAdelantoService } from '../../../../core/services/credito/api.adelanto/api.adelanto';
import { AdelantoEfectivoModel } from '../../../../core/models/credito/api.adelanto/adelanto.model';
import { Intentos } from 'src/app/core/services/utileria/utileria.enum';
import { DialogReintentarComponent } from '../../../../shared/components/modales/modalMaterialReintentar/dialog-overview-msg';
import { MatDialog } from '@angular/material/dialog';
import { ValidacionErrors } from '../../../../core/services/utileria/validacion.error';
import { PrestamoPersonalModel } from 'src/app/core/services/credito/api.prestamos/PrestamoPersonal.model';
export class AtributosMensaje {
  nombre: string;
  valor: string;
}
export class Mensaje {
  mensaje: string;
  atributosMensaje: Array<AtributosMensaje>;
  clases: string;
}

enum Producto {
  ADELANTO_EFECTIVO = 'Adelanto de Efectivo',
  PRESTAMO = 'Préstamo Personal'
}
@Component({
  selector: 'app-aprobado',
  templateUrl: './aprobado.component.html'
})
export class AprobadoComponent implements OnInit, OnDestroy {

  public producto: number;
  txtProducto = '';
  tipoRespuesta;
  parent: AppComponent;
  idProductoRespuesta;
  adelanto: AdelantoEfectivoModel;
  prestamo: PrestamoPersonalModel;
  nombreEmpleado;
  private intentos: number;
  mensajes: Array<Mensaje>;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private apiAdelanto: ApiAdelantoService,
    private validacionError: ValidacionErrors,
     parent: AppComponent) {
    this.parent = parent;

  }

  ngOnInit(): void {
    this.intentos = 1;
    if (window.sessionStorage) {
      this.producto = parseInt(sessionStorage.getItem('producto_solicitud'), 10);
      this.idProductoRespuesta = sessionStorage.getItem('idProductoRespuesta') !== undefined
        && sessionStorage.getItem('idProductoRespuesta') != null ?
        sessionStorage.getItem('idProductoRespuesta') : '';
      this.nombreEmpleado = sessionStorage.getItem('nombrePila') !== undefined ?
        sessionStorage.getItem('nombrePila').split(' ')[0] : '';
    }
    if (this.producto != null) {
      this.txtProducto = this.producto === 1 ? Producto.ADELANTO_EFECTIVO : Producto.PRESTAMO;
    }

    this.parent.bodyComponent.ocultarBody('none', 'body-d', 'head-d');
    this.parent.headerComponent.ocultarTitulo('none');
    this.mensajes = sessionStorage.getItem('mensajesNotificacion') !== undefined ?
    JSON.parse(sessionStorage.getItem('mensajesNotificacion')) : [];
    if (this.mensajes.length > 0 ) {
      this.mensajes.forEach(mensaje => {
        mensaje.clases = '';
        mensaje.atributosMensaje.forEach(atributo => {
          if (atributo.nombre === 'colorMensaje') {
            switch (atributo.valor) {
              case '1': mensaje.clases = mensaje.clases + 'textoColorRojo '; break;
              case '2': mensaje.clases = mensaje.clases + 'textoColorVerde '; break;
              case '3': mensaje.clases = mensaje.clases + 'textoColorNegro '; break;
            }
          } else if (atributo.nombre === 'tipoMensaje') {
            switch (atributo.valor) {
              case '1': mensaje.clases = mensaje.clases + 'tamanoTexto1 '; break;
              case '2': mensaje.clases = mensaje.clases + 'tamanoTexto2 '; break;
              case '3': mensaje.clases = mensaje.clases + 'tamanoTexto3 '; break;
            }
          } else if (atributo.nombre === 'estiloMensaje') {
            switch (atributo.valor) {
              case '1': mensaje.clases = mensaje.clases + 'textoNegritas '; break;
            }
          }
        });
      });
    }

    this.obtenerCredito();

  }

  ngOnDestroy(): void {
    this.parent.bodyComponent.ocultarBody('block', 'body', 'head');
    this.parent.headerComponent.ocultarTitulo('block');
  }

  routeFunction(ruta: string) {
    this.router.navigate([ruta], { skipLocationChange: false });
  }

  validaTipoAutorizado() {
    return this.tipoRespuesta === 6 || this.tipoRespuesta === 5;
  }

  continuarSinCredito() {
    sessionStorage.removeItem('idProductoRespuesta');
    sessionStorage.removeItem('producto_solicitud');
    this.router.navigate(['/home'], { skipLocationChange: false });

  }
  obtenerCredito() {

    if(this.producto === 2){
      this.apiAdelanto.getAdelantoPorSolicitud(this.idProductoRespuesta).subscribe(prestamo => {
        this.intentos = 1;
        this.prestamo = prestamo.resultado as PrestamoPersonalModel;
        console.log(this.prestamo);
        this.tipoRespuesta = this.prestamo.estatusPrestamo;
      //  if (this.adelanto.expedientes != null && this.adelanto.expedientes !== undefined) {
        //  sessionStorage.setItem('expedientes', JSON.stringify(this.adelanto.expedientes));
        //}
      }, error => {
        this.manejoErrorConexion(error);
      });

    }else{
      this.apiAdelanto.getAdelantoPorSolicitud(this.idProductoRespuesta).subscribe(adelanto => {
        this.intentos = 1;
        this.adelanto = adelanto.resultado as AdelantoEfectivoModel;
        console.log(this.adelanto);
        this.tipoRespuesta = this.adelanto.idEstatus;
        if (this.adelanto.expedientes != null && this.adelanto.expedientes !== undefined) {
          sessionStorage.setItem('expedientes', JSON.stringify(this.adelanto.expedientes));
        }
      }, error => {
        this.manejoErrorConexion(error);
      });

    }

  }


  manejoErrorConexion(error) {
    if (error.status === 500 ) {
      if (this.intentos < Intentos.MAX_INTENTOS) {
        this.intentos++;
        this.dialog.closeAll();
        this.openDialogReintentar('Ocurrio un problema de conexión. Intentalo nuevamente', 'Reintentar', true);
      } else if (this.intentos >= Intentos.MAX_INTENTOS) {
        this.dialog.closeAll();
        sessionStorage.removeItem('idProductoRespuesta');
        sessionStorage.removeItem('producto_solicitud');
        this.openDialogReintentar('Ocurrio un problema de conexión. Por favor inténtalo más tarde', 'OK', false);
        this.router.navigate(['/home'], { skipLocationChange: false });
      }
    } else {
      this.dialog.closeAll();
        sessionStorage.removeItem('idProductoRespuesta');
        sessionStorage.removeItem('producto_solicitud');
        this.openDialogReintentar(this.validacionError.getError(error), 'OK', false);
        this.router.navigate(['/home'], { skipLocationChange: false });
    }

  }

  openDialogReintentar(msg: string, boton: string, reintento: boolean): void {
    const dialogRef = this.dialog.open(DialogReintentarComponent, {
      width: '500px',
      data: {
        message: msg,
        boton: boton,
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      if (reintento) {
        this.obtenerCredito();

      }
    });
  }


}
