import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Observable } from 'rxjs';

import { UserModel } from '../models/user.model';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenModel } from '../models/token.model';
import * as CryptoJS from 'crypto-js';


@Injectable({ providedIn: 'root' })
export class UserService {
  private currentUserSubject = new BehaviorSubject<UserModel>({} as UserModel);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private httpClient: HttpClient,
    private router: Router
  ) { }

  iniciarSesion() {
    // If JWT detected, attempt to get & store user's info
    const codeVerifier = this.generarCodeVerifier(128);
    sessionStorage.setItem('codeVerifier', codeVerifier);
    const codeVerifierHash = CryptoJS.SHA256(codeVerifier).toString(CryptoJS.enc.Base64);
    const codeChallenge = codeVerifierHash
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
    let uri = environment.okta_url_authorize;
    uri += '&response_type=' + environment.okta_response_type
    uri += '&client_id=' + environment.okta_client_id
    uri += '&redirect_uri=' + environment.redirectUri
    uri += '&code_challenge=' + codeChallenge
    uri += '&code_challenge_method=' + environment.okta_code_challenge_method
    uri += '&scope=' + environment.okta_scope
    uri += '&state='  + environment.okta_state
    console.log(uri);

    this.router.navigate(['/externalRedirect', { externalUrl: uri }], {
        skipLocationChange: true,
    });
  }

  public obtenerBearerOkta(): Observable<any> {
    const parametros = new URLSearchParams();
        parametros.set('code', sessionStorage.getItem('jwtToken'));
        parametros.set('grant_type','authorization_code');
        parametros.set('client_id',environment.okta_client_id);
        parametros.set('code_verifier',sessionStorage.getItem('codeVerifier'));
        parametros.set('redirect_uri', environment.redirectUri);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.httpClient.post(environment.okta_url_token, parametros.toString(), {headers: headers});
  }

  public cerrarSesionOKTA(): Observable<any> {
    const parametros = new URLSearchParams();
        parametros.set('id_token_hint', sessionStorage.getItem('id_token'));
        parametros.set('post_logout_redirect_uri', environment.redirectUri);
        parametros.set('state',environment.okta_state);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.httpClient.post(environment.okta_url_token, parametros.toString(), {headers: headers});
  }

  public obtenerInformacionUsuarioOkta(token: string): Observable<any> {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + token

    });
    return this.httpClient.get(environment.okta_url_user_info, {headers: headers});
  }

  public obtenerTokenApigee(): Observable<any> {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(environment.api_client_id + ':' + environment.api_client_secret)

    });
    const parametros = new URLSearchParams();
        parametros.set('grant_type', 'client_credentials');
    return this.httpClient.post(environment.api_url_token, parametros.toString(), {headers: headers});
  }

  public generarCodeVerifier(tamaño: number): string {
    let codigo='';
    const caracteres= 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890abeneficiosyzxdrtlopujgs';
    const tamanoCaracteres = caracteres.length;
    for (let i = 0; i < tamaño; i++) {
      codigo += caracteres.charAt(Math.floor(Math.random() * tamanoCaracteres));
    }
    return codigo;
  }

  setNumeroEmpleado(numeroEmpleado:string) {
     sessionStorage.setItem('numeroEmpleado', CryptoJS.AES.encrypt(numeroEmpleado.trim(), environment.contra_crypto).toString() );
  }

  getNumeroEmpleado(): string{
    if(sessionStorage.getItem('numeroEmpleado'))
    return CryptoJS.AES.decrypt(sessionStorage.getItem('numeroEmpleado'), environment.contra_crypto).toString(CryptoJS.enc.Utf8);
    return null;
  }

  public recoverToken() {
    return this.jwtService.getToken();
  }

  public get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  setAuth(user: UserModel) {
    // dependiendo de lo que retornen los servicios de inicio de sesion
    // obtener el User y el token de sesion
    this.jwtService.saveToken(user.token);

    this.currentUserSubject.next(user);

    this.isAuthenticatedSubject.next(true);
  }

  cierreSesion() {
    // Remove JWT from sessionStorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next({} as UserModel);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    // Remove other data
    this.jwtService.destroyData();
  }
  
  public obtenerTokenApigeeOnpremise(): Observable<any> {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(environment.api_client_id_onpremise + ':' + environment.api_client_secret_onpremise)

    });
    const parametros = new URLSearchParams();
        parametros.set('grant_type', 'client_credentials');
    return this.httpClient.post(environment.api_url_token_onpremise, parametros.toString(), {headers: headers});
  }
}