import { Component, OnInit, OnDestroy, Host } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AltaDocumentosService } from 'src/app/core/services/credito/altaDocumentos.service';
import { Intentos, OpcionesIntentosAltaDocumentos } from 'src/app/core/services/utileria/utileria.enum';
import { MatDialog } from '@angular/material/dialog';
import { ArchivoService } from '../../../../core/services/archivos.service';
import { DialogReintentarComponent } from '../../../../shared/components/modales/modalMaterialReintentar/dialog-overview-msg';
import { DialogOverviewMsg } from '../../../../shared/components/modales/modalMaterialMsg/dialog-overview-msg';
import { DialogMensajeComponent } from '../../../../shared/components/modales/modalMaterialCancelaciones.1/dialog-overview-msg1';
import { DialogOverview } from '../../../../shared/components/modales/modalMaterial/dialog-overview';
import { DialogPreviewComponent } from '../../../../shared/components/modales/modalPreview/dialog-overview-prev';
import { ApiConsentimientoService } from 'src/app/core/services/credito/api.consentimiento/api.consentimiento.service';
import { ConsentimientoModel } from 'src/app/core/services/credito/api.consentimiento/consentimiento.model';
import { ApiDocumentosService } from '../../../../core/services/credito/api.documentos/api.documentos';
import { RequestDocumentoModel, DocumentoInModel } from '../../../../core/models/credito/api.documentos/req.documentos.model';
import { ApiValidacionesService } from '../../../../core/services/credito/api.adelanto.validaciones/api.validaciones.service';
import { TipoDocumento, EstatusConsentimiento } from 'src/app/core/services/utileria/utileria.enum';
import { UserService } from 'src/app/core';
import { ValidacionErrors } from '../../../../core/services/utileria/validacion.error';
import { DialogValidacionComponent } from '../../../../shared/components/modales/modalMaterial.1/dialog-overview';

@Component({
  selector: 'app-alta-documentos',
  templateUrl: './altaDocumentos.component.html'
})
export class AltaDocumentosComponent implements OnInit, OnDestroy {

  modalFotoPreview = false;
  ItemModal: File;
  accept = '*';
  files2: File[] = [];
  files1: File[] = [];
  url = '';
  intentos: number;
  validacionCrediticia;
  opcionEnvio;
  estatus;
  clausula;
  hasBaseDropZoneOver = false;
  lastFileAt: Date;
  sendableFormData: FormData; // populated via ngfFormData directive
  sendableFormData1: FormData; // populated via ngfFormData directive
  intentosBuro = 0;
  requestDocumentos;
  // Implementar para setear estas variables
  numEmpleado: string;
  btnContinuar: string;
  idProducto = 0;
  idProductoRespuesta = '';
  expedientes = [];
  expedientesIdentificacion = [];
  expedientesComprobante = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private consentimientoService: ApiConsentimientoService,
    private altaDocumentosService: AltaDocumentosService,
    private archivoService: ArchivoService,
    private validacionError: ValidacionErrors,
    private apiDocumentosService: ApiDocumentosService,
    private apiValidacionesService: ApiValidacionesService) {
  }

  ngOnDestroy(): void {
    if (this.idProductoRespuesta !== '') {
      sessionStorage.setItem('folioCredito', this.idProductoRespuesta);
      sessionStorage.removeItem('idProductoRespuesta');
      sessionStorage.removeItem('producto_solicitud');
      sessionStorage.removeItem('expedientes');
    }
  }

  ngOnInit(): void {
    this.intentos = 1;
    this.numEmpleado = window.sessionStorage ? this.userService.getNumeroEmpleado() : '';

    this.expedientes = sessionStorage.getItem('expedientes') !== undefined
    && sessionStorage.getItem('expedientes') != null ?
    JSON.parse(sessionStorage.getItem('expedientes')) : [];
    this.expedientesIdentificacion = this.expedientes.filter(expediente => expediente.idExpediente === 1);
    this.expedientesComprobante = this.expedientes.filter(expediente => expediente.idExpediente === 2);

    this.idProductoRespuesta = sessionStorage.getItem('idProductoRespuesta') !== undefined
      && sessionStorage.getItem('idProductoRespuesta') != null ?
      sessionStorage.getItem('idProductoRespuesta') : '';
    let idProducto = window.sessionStorage ? sessionStorage.getItem('producto_id') : '';
    if (sessionStorage.getItem('idProductoRespuesta')) {
       idProducto = window.sessionStorage ? sessionStorage.getItem('producto_solicitud') : '';
    }
    if (idProducto == null || idProducto === undefined || idProducto.trim() === '') {

      setTimeout(() => {
        this.openDialogMsg1('Por favor selecciona un producto para continuar', true);
      });
      } else {
        this.btnContinuar = this.idProductoRespuesta !== '' ? 'Enviar' : 'Continuar';
        this.idProducto = idProducto !== undefined && idProducto.trim() !== '' ? +idProducto : 0;
        // TODO: API de documentos para obtener los documentos
        this.apiDocumentosService.getObtenerDocumentos(this.numEmpleado, ['' + TipoDocumento.IDENTIFICACION_OFICIAL,
        '' + TipoDocumento.COMPROBANTE_DE_DOMICILIO])
          .subscribe((response) => {
            const documentos = response.resultado.documentos;
            // Metodo para recuperar los archivos de BD
            for (let i = 0; i < documentos.length; i++) {
              if (this.archivoService.validarTipoImagen(documentos[i].extension) !== '' || true) {
                const nombre = documentos[i].nombre + '.' + documentos[i].extension;
                // Get the content type of the image
                const contentType = this.archivoService.validarTipoImagen(documentos[i].extension);
                // get the real base64 content of the file
                const base64 = documentos[i].base64;
                // Convert it to a blob to upload
                const blob = this.archivoService.b64toBlob(base64, contentType, null);
                const fileFinal = this.archivoService.blobToFile(blob, nombre);
                documentos[i].idDocumento === 1 ? this.files1.push(fileFinal) : this.files2.push(fileFinal);
              }
            }

          }, (error) => {
          });

      }


  }

  validarMostrarModal(): void {
    if(this.idProducto ===1){
      this.apiValidacionesService.getValidaciones(5, this.numEmpleado).subscribe((response) => {
        this.intentos = 1;
        this.abrirModal(response.resultado.requerido);
      }, (error) => {
        this.manejoErrorConexion(error, OpcionesIntentosAltaDocumentos.VALIDA_MODAL);
      });
    }

  }

  abrirModal(validacionCrediticia): void {
    if (validacionCrediticia === true) {
      this.apiDocumentosService.getObtenerDocumentos(this.numEmpleado, ['' +
        TipoDocumento.AUTORIZACION_DE_CONSULTA_A_BURO]).subscribe((response) => {
          this.intentos = 1;
          const documentos = response.resultado.documentos;
          // Metodo para recuperar los archivos de BD CON
          for (let i = 0; i < documentos.length; i++) {
            if (this.archivoService.validarTipoImagen(documentos[i].extension)) {
              const nombre = documentos[i].nombre + '.' + documentos[i].extension;
              // setear el valor del tipo de contenido
              const contentType = this.archivoService.validarTipoImagen(documentos[i].extension);
              // Obtener el base 64
              const base64 = documentos[i].base64;
              // Convertir it to a blob to upload
              const blob = this.archivoService.b64toBlob(base64, contentType, null);
                const dialogRef = this.dialog.open(DialogOverview, {
                  width: '750px',
                  height: '95vh',
                  data: {
                    titulo: 'Investigación Crediticia',
                    urlPdf: blob,
                    showDownloadButton: false,
                    disableClose: true
                  },
                  autoFocus: false
                });
                dialogRef.afterClosed().subscribe(result => {
                  if (result) {
                    this.intentosBuro++;
                    if(this.idProducto ===1){
                      this.guardarConsentimientos(1, EstatusConsentimiento.ESTATUS_NO_APROBADO,
                        TipoDocumento.AUTORIZACION_DE_CONSULTA_A_BURO);
                    }

                  } else {
                    this.guardarConsentimientos(2, EstatusConsentimiento.ESTATUS_APROBADO, TipoDocumento.AUTORIZACION_DE_CONSULTA_A_BURO);
                  }
                });

            }
          }
        }, (error) => {
          this.validacionCrediticia = validacionCrediticia;
          this.manejoErrorConexion(error, OpcionesIntentosAltaDocumentos.ABRIR_MODAL);
        });

    } else {
      this.idProductoRespuesta !== '' ? this.router.navigate(['/resumen'], { skipLocationChange: false }) :
        this.router.navigate(['/referenciasfamiliares'], { skipLocationChange: false });
    }
  }

  /* Metodo para el guardado de los consentimientos (Identificacion Oficial, Comprobante, Inv Crediticia)
  y realiza la accion siguiente de acuerdo al tipo de consentimiento a guardar
  case 1: Muestra el modal de validación que debe aceptar la inv
  case 2: Se acepto el consentimiento de inv crediticia, se envia a referenciasfamiliares
  case 3: Se guardo identificacion, se envia a guardar nuevamente el comprobante
  case 4: Se guardo comprobante, se abre el modal de investigacion.
  */
  guardarConsentimientos(opcionEnvio: number, estatus: number, clausula: number) {

    const request = new ConsentimientoModel();
    request.numeroEmpleado = this.numEmpleado;
    request.idClausula = clausula;
    request.idProducto = 1;
    request.estatus = estatus;
    if(opcionEnvio !== 5  ){
      this.consentimientoService.postConsentimiento(request).subscribe(response => {
        this.intentos = 1;
        switch (opcionEnvio) {
          case 1: this.openDialogMsg('Recuerda que es necesario contar con tu autorización para realizar la investigación' +
            ' crediticia para continuar con tu solicitud', true); break;
          case 2: this.idProductoRespuesta !== '' ? this.router.navigate(['/resumen'], { skipLocationChange: false }) :
            this.router.navigate(['/referenciasfamiliares'], { skipLocationChange: false }); break;
          case 3: this.guardarConsentimientos(4, 1, TipoDocumento.COMPROBANTE_DE_DOMICILIO); break;
          case 4: this.validarMostrarModal();

        }

      }, (error) => {
        this.opcionEnvio = opcionEnvio;
        this.estatus = estatus;
        this.clausula = clausula;
        this.manejoErrorConexion(error, OpcionesIntentosAltaDocumentos.GUARDAR_CONSENTIMIENTOS);
      });
    }else if (opcionEnvio ===5){
      this.idProductoRespuesta !== '' ? this.router.navigate(['/resumen'], { skipLocationChange: false }) :
            this.router.navigate(['/referenciasfamiliares'], { skipLocationChange: false });

    }


  }

  openDialogMsg(msg: string, route: boolean): void {
    const dialogRef = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(() => {
      if (route && this.intentosBuro === 2) {
        this.router.navigate(['/home'], { skipLocationChange: false });
      } else if (route && this.intentosBuro < 2) {
        this.validarMostrarModal();
      }
    });
  }

  openDialogMsg1(msg: string, route: boolean): void {
    const dialogRef = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(() => {
      if (route ) {
        this.router.navigate(['/home'], { skipLocationChange: false });
      }

    });
  }

  public continuar() {
    const dialogRef = this.dialog.open(DialogValidacionComponent, {
      width: '500px',
      data: {
        message:
          "Antes de continuar, REVISA: ",
          message1:
          ' Identificación oficial  vigente, legible y ambos lados ',
          message2:
          'Comprobante de domicilio vigente (no mayor a 3 meses), legible, docto. completo y sea de la dirección que capturaste',

          btn1: 'Corregir',
        btn2: 'Si, continuar'
      },
      autoFocus: false,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (this.validaImagenesIdentificacion() && this.cambiaImagenesComprobante()) {
            this.obtenerDocumentos();


        } else {
          this.openDialogMsg('Favor de verificar que todos tus documentos estén digitalizados', false);
        }

      }
    });}



  obtenerDocumentos() {
    const request = new RequestDocumentoModel();
    request.idProducto = this.idProducto;
    request.numeroEmpleado = this.numEmpleado;


    this.obtenerB64(this.files1[0]).then(respuesta => {
      const documento = new DocumentoInModel();

      documento.nombre = this.files1[0].name.split('.')[0];
      documento.idDocumento = TipoDocumento.IDENTIFICACION_OFICIAL;
      documento.base64 = <string>respuesta;
      documento.extension = this.files1[0].name.split('.')[this.files1[0].name.split('.').length - 1 ];
      request.documentos.push(documento);
      if (this.files1.length > 1) {
        this.obtenerB64(this.files1[1]).then(respuesta1 => {
          const documento1 = new DocumentoInModel();

          documento1.nombre = this.files1[1].name.split('.')[0];
          documento1.idDocumento = TipoDocumento.IDENTIFICACION_OFICIAL;
          documento1.base64 = <string>respuesta1;
          documento1.extension = this.files1[1].name.split('.')[this.files1[1].name.split('.').length - 1 ];
          request.documentos.push(documento1);
          this.obtenerB64(this.files2[0]).then(respuesta2 => {
            const documento2 = new DocumentoInModel();
            documento2.nombre = this.files2[0].name.split('\.')[0];
            documento2.idDocumento = TipoDocumento.COMPROBANTE_DE_DOMICILIO;
            documento2.base64 = <string>respuesta2;
            documento2.extension = this.files2[0].name.split('\.')[this.files2[0].name.split('\.').length - 1];

            request.documentos.push(documento2);
            this.requestDocumentos = request;
            this.guardarDocumentos(request);

          });
        });
      } else {
        this.obtenerB64(this.files2[0]).then(respuesta2 => {
          const documento2 = new DocumentoInModel();

          documento2.nombre = this.files2[0].name.split('\.')[0];
          documento2.idDocumento = TipoDocumento.COMPROBANTE_DE_DOMICILIO;
          documento2.base64 = <string>respuesta2;
          documento2.extension = this.files2[0].name.split('\.')[this.files2[0].name.split('\.').length - 1];
          request.documentos.push(documento2);
          this.requestDocumentos = request;
          this.guardarDocumentos(request);
        });
      }

    });

  }

  guardarDocumentos(request: RequestDocumentoModel) {
    if(this.idProducto ===1){
      this.apiDocumentosService.postGuardarDocumentos(request).subscribe(documentos => {
        this.intentos = 1;
        this.guardarConsentimientos(3, 1, TipoDocumento.IDENTIFICACION_OFICIAL);

      }, (error) => {
        this.manejoErrorConexion(error, OpcionesIntentosAltaDocumentos.GUARDAR_DOCUMENTOS);
      });
    } else if (this.idProducto ===2) {
      this.apiDocumentosService.postGuardarDocumentos(request).subscribe(documentos => {
        this.intentos = 1;
        this.guardarConsentimientos(5, 1, TipoDocumento.IDENTIFICACION_OFICIAL);

      }, (error) => {
        this.manejoErrorConexion(error, OpcionesIntentosAltaDocumentos.GUARDAR_DOCUMENTOS);
      });

    }

  }

  manejoErrorConexion(error, opcion: number) {
    if (error.status === 400 || (error.status > 402 && error.status < 500)) {
      this.dialog.closeAll();
      this.openDialogReintentar(this.validacionError.getError(error), 'OK', false, opcion);
    } else if (error.status === 500 && this.intentos < Intentos.MAX_INTENTOS) {
      this.intentos++;
      this.dialog.closeAll();

      this.openDialogReintentar('Ocurrio un problema de conexión. Intentalo nuevamente', 'Reintentar', true, opcion);
    } else if (error.status === 500 && this.intentos >= Intentos.MAX_INTENTOS) {
      this.dialog.closeAll();
      this.openDialogReintentar('Ocurrio un error al conectar con los servicios. Por favor inténtalo más tarde', 'OK', false, opcion);
      this.router.navigate(['/home'], { skipLocationChange: false });
      this.intentos = 1;
    }
  }

  openDialogReintentar(msg: string, boton: string, reintento: boolean, opcion: number): void {
    const dialogRef = this.dialog.open(DialogReintentarComponent, {
      width: '500px',
      data: {
        message: msg,
        boton: boton,
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      if (reintento) {
        switch (opcion) {
          case 1: this.guardarDocumentos(this.requestDocumentos); break;
          case 2: this.validarMostrarModal(); break;
          case 3: this.abrirModal(this.validacionCrediticia); break;
          case 4: this.guardarConsentimientos(this.opcionEnvio, this.estatus, this.clausula); break;
          default: console.log('opcion no valida');
        }
      }
    });
  }

  routeFunction(ruta: String): void {
    this.router.navigate([ruta], { skipLocationChange: false });
  }

  obtenerB64 = (item: File) => {
    const promise = new Promise((resolve, reject) => {
      let res: string;
      const reader = new FileReader();
      reader.readAsDataURL(item);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          res = reader.result;
        } else {
          res = reader.result.toString();
        }
        res = res.split(',')[1];
        resolve(res);
      };
    });
    return promise;
  }

  openDialogPreview(imagen, urlPdf) {
    this.dialog.open(DialogPreviewComponent, {
      width: '750px',
      data: {
        imagen: imagen,
        urlPdf: urlPdf,
        showDownloadButton: false,
        disableClose: true
      }
    });
  }
  eliminarImagen(i: number, e) {
    this.files1.splice(i, 1);
    e.stopPropagation();
    e.preventDefault();

  }

  eliminarComprobante(i: number, e) {
    this.files2.splice(i, 1);
    e.stopPropagation();
    e.preventDefault();

  }


  mostrarImagen(item: File, e) {
    // Validacion de que se mostrar en el Modal si un PDF o una Imagen
    if (['image/png', 'image/jpeg', 'image/jpg'].indexOf(item.type) >= 0) {
      this.openDialogPreview(item, null);
    }
    e.stopPropagation();
    e.preventDefault();
  }

  cambiaImagenesIdentificacion() {
    if (this.files1.length > 2 || this.files1.length === 2) {
      this.files1.splice(2, this.files1.length - 2);
      return true;
    } else if (this.files1.length < 2) {
      return false;
    }
  }
  validaImagenesIdentificacion() {
    if (this.files1.length > 0) {
      return true;
    }
    return false;
  }

  cambiaImagenesComprobante() {
    if (this.files2.length > 1 || this.files2.length === 1) {
      this.files2.splice(1, this.files2.length - 1);
      return true;
    } else if (this.files2.length < 1) {
      return false;
    }
  }

  obtenerTokens() {
    this.altaDocumentosService.obtenerTokens();
  }

  openDialogCancelacionesMensaje(): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: {
        message: this.idProductoRespuesta === '' ? '¿Estás seguro que deseas salir? No se guardará el avance.'
        : '¿Estás seguro que deseas salir?',
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.router.navigate(['/home'], { skipLocationChange: false });
      }
    });
  }
}
