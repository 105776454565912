<div class="container peque">
    <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center">
        <div class="resumenGral">
                <span class="AdE" *ngIf="producto===1">Adelanto de<br>Efectivo</span>
                <span class="Ps" *ngIf="producto===2">Préstamo<br>Personal</span>
                <img class="all" alt="" src="../../../../../assets/img/resumen.png"/>
        </div>
        <h4 class=" name text-center">{{nombreEmpleado}},</h4>
        <p class="sub"><strong>estás a punto de lograr tu meta</strong></p>
        <p *ngIf="producto===1" class="text-center">Hemos recibido tu solicitud de efectivo,<br>por la cantidad de:</p>
        <h5 class="cantidad"  *ngIf="adelanto">{{adelanto.montoSolicitado|conversor}}</h5>
        <hr class="double">
        <table class="table table-striped" *ngIf="adelanto">
            <tbody>
                <tr>
                    <td class="col-6 text-right">Fecha de solicitud:</td>
                    <td class="col-6 text-left">{{(adelanto.fechaSolicitud|fechaResumen)}}</td>
                </tr>
                <tr>
                    <td class="col-6 text-right">Número de folio:</td>
                    <td class="col-6 text-left">{{adelanto.idAdelanto}}</td>
                </tr>
                <tr>
                    <td class="col-6 text-right">Estatus de la solicitud:</td>
                    <td class="col-6 text-left">{{adelanto.estatus}}</td>
                </tr>
            </tbody>
        </table>

        <p *ngIf="producto===2" class="text-center">Hemos recibido la solicitud de Préstamo Personal GS,<br>por la cantidad de:</p>
        <h5 class="cantidad"  *ngIf="prestamo">{{prestamo.montoPrestamo|conversor}}</h5>
        <hr class="double">
        <table class="table table-striped" *ngIf="prestamo">
            <tbody>
                <tr>
                    <td class="col-6 text-right">Pago {{prestamo.tipoNomina}}:</td>
                    <td class="col-6 text-left">{{prestamo.montoPago|conversor}}</td>
                </tr>
                <tr>
                    <td class="col-6 text-right">Plazo de pago:</td>
                    <td class="col-6 text-left">{{prestamo.plazo}} pagos {{prestamo.tipoNomina}}es </td>
                </tr>
                <tr>
                    <td class="col-6 text-right">Folio:</td>
                    <td class="col-6 text-left">{{prestamo.folioPP}}</td>
                </tr>
            </tbody>
        </table>


        <hr class="double">
        <p class="mensaje">En tu correo electrónico y/o a través de este sitio<br>
            recibirás la respuesta a tu solicitud.
            <br>
            La podrás consultar ingresando nuevamente al portal de Beneficios<br>Financieros en tu sección de notificaciones “🔔”.</p>
        <p class="contactanos">:.&nbsp;&nbsp;&nbsp;Contáctanos&nbsp;&nbsp;&nbsp;.:</p>
        <p class="telefono"><img src="../../../../../assets/img/tel.svg" alt="teléfono" /> 55 7099 1199 – Opción 5</p>
        <a class="correo" href="mailto:beneficiosfinancierosgs@gruposalinas.com.mx">beneficiosfinancierosgs@gruposalinas.com.mx</a>
        <div class="btns_centrados">
            <button name="btnSalir" class="btn all salir" (click)="routeFunction('/home')" routerLinkActive="router-link-active">Salir</button>
        </div>
    </div>
</div>

<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }  
    
    function redireccionAlSitio() {  
        try {
            var surl = self;  
            if (validateURL(surl))  
            top.location.href = surl;
            else {  
            throw new InvalidURLException();  
            }  
        } catch (e) {  
            if (e instanceof InvalidURLException)  
            alert(e.message);  
        }  
    }  

    function InvalidURLException() {  
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";  
        this.toString = function () {  
            return this.message  
        };  
    }  

    function validateURL(surl) {  
        var url = parseURL(surl);  
        var urlHostname = url.hostname.trim();  

        if (urlHostname == '') {  
            return true;  
        } else {  
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {  
            return true;  
            } else {
            return false;  
            }
        }  
    }  

    function parseURL(url) {
    var a = document.createElement('a');  
    a.href = url;  
    return {  
        source: url,  
        protocol: a.protocol.replace(':', ''),  
        hostname: a.hostname,  
        host: a.host,  
        port: a.port,  
        query: a.search,  
        params: (function () {  
            var ret = {},  
                seg = a.search.replace(/^\?/, '').split('&'),  
                len = seg.length, i = 0, s;  
            for (; i < len; i++) {  
                if (!seg[i]) { continue; }  
                s = seg[i].split('=');  
                ret[s[0]] = s[1];  
            }  
            return ret;  
        })(),  
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],  
        hash: a.hash.replace('#', ''),  
        path: a.pathname.replace(/^([^\/])/, '/$1'),  
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],  
        segments: a.pathname.replace(/^\//, '').split('/')  
        };  
    }
</script>  