import { Component, ViewChild, AfterContentInit, OnInit } from '@angular/core';
import { Producto } from './core/services/home/home-auth-resolver.service';
import { Router } from '@angular/router';
import { BodyComponent, HeaderComponent } from './shared';
import { SpinnerServiceWS } from '../app/core/interceptors/spinner.service';
import { SpinnerService } from './shared/components/spinnner/spinner.service';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-root',
  template: `<app-xss></app-xss><app-header></app-header><app-body></app-body><app-footer></app-footer>`
})
export class AppComponent implements AfterContentInit, OnInit {

  @ViewChild(BodyComponent, { static: true }) bodyComponent: BodyComponent;
  @ViewChild(HeaderComponent, { static: true }) headerComponent: HeaderComponent;

  title = 'beneficios-socios';
  producto: number;
  showSpinner: boolean;

  constructor(
    private fpinnerService: SpinnerService,
    private spinnerService: SpinnerServiceWS,
    private router: Router,
    private bnIdle: BnNgIdleService
  ) {
  }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
    this.cargarInformacion();
    this.overrideClipboard();
    this.crearTimerDeSesion();
  }

  overrideClipboard(): void {
    document.addEventListener('copy', (event) => {
      event.clipboardData.setData('text/plain', '');
      event.clipboardData.setData('text/html', '');
      event.preventDefault();
    });
    document.addEventListener('cut', (event) => {
      event.clipboardData.setData('text/plain', '');
      event.clipboardData.setData('text/html', '');
      event.preventDefault();
    });
  }

  ngAfterContentInit() { // or ngOnInit or whatever
    setTimeout(() => {
      this.spinnerService.onVisibilityChange((value) => {
        this.fpinnerService.abrirSpinner1(value);
      });
    });
  }

  continuarConProducto(prodcuto, ruta): void {
    this.guardarInformacion(prodcuto);
    this.bodyComponent.setProgressAside(prodcuto);
    this.setProducto(prodcuto);
    this.router.navigateByUrl(ruta , {skipLocationChange: false});
  }

  cargarInformacion(): void {
    if (window.sessionStorage) {
      this.producto = +sessionStorage.getItem('producto_id');
      this.bodyComponent.setProgressAside(this.producto);
      this.setProducto(this.producto);
    }
  }

  guardarInformacion(producto): void {
    if (window.sessionStorage) {
      sessionStorage.producto_id = producto;
    }
  }

  setProducto(producto): void {
    // imgProducto.removeAttribute('src');
    switch (producto) {
      case Producto.ADELANTO_EFECTIVO:
        this.bodyComponent.setImagenAside('./assets/img/img_col_adelanto.png');
        this.headerComponent.cambiarTitulosProducto('Adelanto de Efectivo', 'Adelanto de Efectivo', 'Créditos');
        this.bodyComponent.setStyleAside(producto);
        break;
      case Producto.PRESTAMO:
        this.bodyComponent.setImagenAside('./assets/img/img_col_prestamo.png');
        this.headerComponent.cambiarTitulosProducto('Préstamo Personal', 'Préstamo Personal', 'Créditos');
        this.bodyComponent.setStyleAside(producto);
        break;
      case Producto.PERFIL_SOCIO:
        this.bodyComponent.setImagenAside('');
        this.headerComponent.cambiarTitulosProducto('Perfil del Empleado', 'Perfil del Empleado', 'Créditos');
        break;
      default:
        this.bodyComponent.setImagenAside('./assets/img/img_col_adelanto.png');
        this.headerComponent.cambiarTitulosProducto('Créditos', 'Créditos', 'Créditos');
        this.bodyComponent.setStyleAside(producto);
        break;
    }
  }

  crearTimerDeSesion(): void{
    this.bnIdle.startWatching(600).subscribe((res) => {
      if(res && this.headerComponent.sesionActiva) {
        this.headerComponent.cerrarSesion();
      }
    })
  }
}
