import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HeaderService } from '../header.options';
import { ResponseDocumentoModel } from '../../../models/credito/api.documentos/resp.documentos.model';
import { RequestDocumentoModel } from '../../../models/credito/api.documentos/req.documentos.model';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ValidacionErrors } from '../../utileria/validacion.error';
import { DialogOverviewMsg} from '../../../../shared/components/modales/modalMaterialMsg/dialog-overview-msg';
@Injectable({ providedIn: 'root' })
export class ApiDocumentosService {

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  private queryParams = '?';
  constructor(
    private httpClient: HttpClient,
    private headers: HeaderService,
    private dialog: MatDialog,
    private validacionError: ValidacionErrors

  ) { }

  // Metodo para obtener los documentos del cliente
  // Recibe parametros numeroEmpleado y monto para calcular
  getObtenerDocumentos(numeroEmpleado: string, tipoDocumento: string[]): Observable<any> {

    const parametros = new URLSearchParams();
    parametros.set('numeroEmpleado', numeroEmpleado);
    parametros.set('idDocumento',  this.obtenerTiposDocumentos(tipoDocumento) );
    console.log(parametros.toString())
   return this.httpClient.get<ResponseDocumentoModel>(
      environment.api_url_documentos + this.queryParams + parametros.toString(),
      { headers: this.headers.getHeaderOption(false) }).pipe(
        catchError( e => {
    throw e;
        })
      );

  }

    // Metodo para obtener los documentos del cliente
  // Recibe parametros numeroEmpleado y monto para calcular
  getObtenerDocumentosPrestamo(numeroEmpleado: string, tipoDocumento: string[],idProducto: number): Observable<any> {

    const parametros = new URLSearchParams();
    parametros.set('numeroEmpleado', numeroEmpleado);
    parametros.set('idDocumento',  this.obtenerTiposDocumentos(tipoDocumento) );
    parametros.set('idProducto', ''+ idProducto );
    console.log(parametros.toString())
   return this.httpClient.get<ResponseDocumentoModel>(
      environment.api_url_documentos + this.queryParams + parametros.toString(),
      { headers: this.headers.getHeaderOption(false) }).pipe(
        catchError( e => {
    throw e;
        })
      );

  }


  getObtenerExpedientes(numeroEmpleado: string, folio: string, idProducto: string): Observable<any> {

    const parametros = new URLSearchParams();
    parametros.set('numeroEmpleado', numeroEmpleado);
    parametros.set('folio',  folio );
    parametros.set('idProducto',  idProducto );


   return this.httpClient.get<ResponseDocumentoModel>(
      environment.api_url_expedientes + parametros.toString(),
      { headers: this.headers.getHeaderOption(false) }).pipe(
        catchError( e => {
    throw e;
        })
      );

  }
  // Metodo para guardar los documentos del socio
  // Recibe parametros numeroEmpleado y monto para calcular y guardar
   postGuardarDocumentos(documentosReq: RequestDocumentoModel): Observable<any> {
    documentosReq.idProducto =1;
    return this.httpClient.post<any>(
       environment.api_url_documentos, documentosReq,
       { headers: this.headers.postHeaderOption(false) }).pipe(
        catchError( e => {
    throw e;
        })
      );

   }


   openDialogMsg(msg: string): void {
    this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true,
        autoFocus: false
      }
    });
  }


  obtenerTiposDocumentos(documentos: string []) {
    const coma = ',';
    let documentosOut = '';
    if (documentos !== undefined && documentos.length > 0) {
      documentosOut = documentos[0];
      for (let i = 1 ; i < documentos.length; i++) {
        documentosOut = documentosOut + coma + documentos[i];
      }
      return documentosOut;
    }

    return documentosOut;

  }

}
