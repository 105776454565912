import { Injectable, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { UserService } from '../user.service';
import { UserModel } from '../../models';
import { TokenApiService } from '../token.service';
import { DialogOverviewMsg } from '../../../shared/components/modales/modalMaterialMsg/dialog-overview-msg';
import { JwtService } from '../jwt.service';
import { environment } from '../../../../environments/environment';
import { HomeService } from '../home.service';

@Injectable()
export class AuthGuardAccessTokenService implements CanActivate {

  constructor(private router: Router,
    private userService: UserService,
    private tokenService: TokenApiService,
    private jwtService: JwtService,
    private homeService: HomeService,
    private dialog: MatDialog) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const uri = window.location.href;
    let bandera = false;
    if (uri.indexOf('code') !== -1) {
      bandera = true;
      const inicioToken = window.location.href.indexOf('code=') + 5;
      let token = window.location.href.substring(inicioToken, window.location.href.indexOf('&state'));
      token = token.length > 20 ? token : this.userService.recoverToken();
      const data = {
        'token': token
      };
      // Guardamos la información del token
      this.userService.setAuth(new UserModel(data.token));
      this.guardarUsuarioSesion();
    }

    if (uri.indexOf('guatemala') !== -1) {
      sessionStorage.setItem('pais', '2')
    }


    if ((this.userService.recoverToken() && this.tokenService.validarToken()) || bandera == false) {
      return true;
    }
    this.jwtService.destroyData();
    this.openDialogMsg('Inicia sesión para continuar', true);
    return false;
  }

  private guardarUsuarioSesion() {
    /*     sessionStorage.setItem('nombrePila', nombrePila);
        this.userService.setNumeroEmpleado(numeroEmpleado);
        sessionStorage.setItem('nombreCompleto', nombreCompleto);
        sessionStorage.setItem('areaEmpleado', areaEmpleado);
        sessionStorage.setItem('puestoEmpleado', puesto);
        sessionStorage.setItem('correoEmpleado', correoEmpleado);
        sessionStorage.setItem('tipoEmpleado', tipoEmpleado); 
        
        //tokenAPI
        this.tokenService.guardarToken(this.jwtService.getToken());
    */
    this.userService.obtenerBearerOkta().subscribe(
      token => {
        console.log(token)
        sessionStorage.setItem('id_token', token.id_token)
        this.userService.obtenerInformacionUsuarioOkta(token.access_token).subscribe(informacionUsuario => {
          console.log(informacionUsuario);
          this.userService.setNumeroEmpleado(informacionUsuario.numeroEmpleado);
          sessionStorage.setItem('nombreCompleto', informacionUsuario.nombreCompleto);
          sessionStorage.setItem('nombrePila', informacionUsuario.nombreCompleto.split(' ')[0]);
          sessionStorage.setItem('areaEmpleado', informacionUsuario.compania);
          sessionStorage.setItem('puestoEmpleado', informacionUsuario.puesto);
          sessionStorage.setItem('correoEmpleado', informacionUsuario.correo);
          sessionStorage.setItem('tipoEmpleado', informacionUsuario.tipoEmpleado);
          this.userService.obtenerTokenApigee().subscribe(tokenApiGee => {
            console.log(tokenApiGee);
            this.tokenService.guardarToken(tokenApiGee.access_token);
            this.userService.obtenerTokenApigeeOnpremise().subscribe(tokenApiGeeOnpremise => {
              console.log(tokenApiGeeOnpremise);
              this.tokenService.guardarTokenOnpremise(tokenApiGeeOnpremise.access_token);
            })
            if (sessionStorage.getItem('producto_id')) {
              if (+sessionStorage.getItem('producto_id') === 1) {
                // Rediccionar ADE
                this.homeService.iniciarADE();
              } else if (+sessionStorage.getItem('producto_id') === 2) {
                // Rediccionar CP
                this.homeService.iniciarCP();
              }
            }
          })
          //consumo tokenAPI

        })

        
      },
      err => {
        console.log('Lo sentimos, no fue posible recuperar la información del usuario: ' + JSON.stringify(err));
        if (err.error && err.error.includes('authentication')) {
          this.userService.cierreSesion();
          this.userService.cerrarSesionOKTA();
        }
      }
    );

  }

  private obtenerNombreSinApellidos(nombresEmpleado: string): string {
    const nombres = nombresEmpleado.split(' ');
    let nombrePersona = '';
    nombres.forEach(nombre => {
      nombrePersona += nombre.charAt(0).toUpperCase() + nombre.substr(1).toLowerCase() + ' ';
    });
    return nombrePersona.trim();
  }

  openDialogMsg(msg: string, route: boolean): void {
    const dialogRef = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(() => {
      if (route) {
        this.router.navigateByUrl('/');
      }
    });
  }
}
