<div class="bannerPrestamo">
    <div class="acceso">

    </div>
</div>

<div class="complementos">
    <a class="folleto" href="../../../../../../assets/docs/home/FOLLETO CREDITO_PERSONAL.pdf" target="_blank" rel="noopener noreferrer"> Folleto</a>
    <a class="guia_rapida" href="../../../../../../assets/docs/home/Infografia Final.pdf" target="_blank" rel="noopener noreferrer"> Guía rápida</a>
    <a class="video" (click)="mostrarVideoCP()"> Video</a>
    <a class="faqs" href="../../../../../../assets/docs/home/Preguntas frecuentes_CREDITO_PERSONAL.pdf" target="_blank" rel="noopener noreferrer"> FAQ's</a>
</div>
<h1 class="titleHome">Solicítalo hoy desde la App de Banco Azteca</h1>
<p><strong>¿Qué es?</strong></p>
<p>Es un crédito inmediato que va desde $1,000 hasta $300,000<sup>1</sup> que es depositado en tu cuenta de nómina
</p>
<p><strong>¿Para quién es?</strong></p>
<p>Para colaboradores de empresas de Grupo Elektra<sup>2</sup> que reciban su nómina en una cuenta de Banco Azteca</p>
<p><strong>¿Qué beneficios exclusivos te ofrece?</strong></p>
<ul class="contenido">
    <li>Disposición inmediata</li>
    <li>Sin <strong>Aval</strong> ni <strong>Garantías</strong></li>
    <li>Pagos anticipados sin penalización</li>
    <li>Puedes solicitarlo a través de la App de Banco Azteca  las 24 h, los 365 días</li>
    <li>Seguro de Vida de Liberación de Adeudo <strong>SIN COSTO</strong></li>
</ul>
<p><strong>Características</strong></p>
<ul class="contenido">
    <li>Monto desde $1,000 hasta $300,000</li>
    <li>Plazos: 6, 12, y 18 meses o su equivalencia en quincenas o semanas<sup>3</sup></li>
    <li>Tasa anual Preferencial del <strong>18%</strong></li>
    <li>Pago con cargo automático a tu cuenta de nómina de Banco Azteca</li>
</ul>
<p><strong>Requisitos</strong></p>
<ul class="contenido">
    <li>Contar con la App de Banco Azteca</li>
    <li>Antigüedad mínima de 5 años en Grupo Elektra</li>
    <li>Tener más de 23 años de edad</li>
    <li>Recibir tu nómina en Banco Azteca</li>
    <li>Buen historial crediticio</li>
    <br>
    <p><strong><sup>1</sup>Sujeto a evaluación de crédito</strong></p>
    <p><strong><sup>2</sup>Crédito disponible para: Elektra, Back Office, Banco Azteca e Italika</strong></p>
    <p><strong><sup>3</sup>Periodos: Quincenal (12, 24 y 36) y Semanal (26, 52 y 78)</strong></p>
</ul>


        <div class="row">
            <div class="col-9  text-center" >
                <img class="logoSocio" src="../../../../assets/img/solicita_baz.jpg" style="width:60vw;"/>
            </div>
        </div>
<hr class="prodBottom">
<p class="contactanos text-center">:.&nbsp;&nbsp;&nbsp;Contáctanos&nbsp;&nbsp;&nbsp;.:</p>
<p class="telefono text-center"><img src="../../../../assets/img/tel.svg" alt="teléfono" /> Linea Azteca <strong>55 5447 8810</strong></p>
<div class=" text-center"><a class="correo" target="_blank" rel="noopener noreferrer"
    href="mailto:beneficiosfinancierosgs@gruposalinas.com.mx">beneficiosfinancierosgs@gruposalinas.com.mx</a></div><br><br><br>

<!-------Bot-------->
<div id="botDiv2">
    <app-ps-maia></app-ps-maia>
</div>

<div class="abrir-modal animacion fadeIn" *ngIf="modalVideo">
    <div class="modal zoom" tabindex="-1" role="dialog" style="display:block;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <button (click)="cerrarVideo(1)" type="button" class="close cmodal" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe title="" class="embed-responsive-item"
                        src="../../../../../../assets/videos/PS.mp4"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="abrir-modal animacion fadeIn" *ngIf="modalVideoCP">
        <div class="modal zoom" tabindex="-1" role="dialog" style="display:block;">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <button (click)="cerrarVideo(2)" type="button" class="close cmodal" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe title="" class="embed-responsive-item"
                            src="../../../../../../assets/videos/Video_Credito_Personal_AE_Final.mp4"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>

<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }

    function redireccionAlSitio() {
        try {
            var surl = self;
            if (validateURL(surl))
            top.location.href = surl;
            else {
            throw new InvalidURLException();
            }
        } catch (e) {
            if (e instanceof InvalidURLException)
            alert(e.message);
        }
    }

    function InvalidURLException() {
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";
        this.toString = function () {
            return this.message
        };
    }

    function validateURL(surl) {
        var url = parseURL(surl);
        var urlHostname = url.hostname.trim();

        if (urlHostname == '') {
            return true;
        } else {
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {
            return true;
            } else {
            return false;
            }
        }
    }

    function parseURL(url) {
    var a = document.createElement('a');
    a.href = url;
    return {
        source: url,
        protocol: a.protocol.replace(':', ''),
        hostname: a.hostname,
        host: a.host,
        port: a.port,
        query: a.search,
        params: (function () {
            var ret = {},
                seg = a.search.replace(/^\?/, '').split('&'),
                len = seg.length, i = 0, s;
            for (; i < len; i++) {
                if (!seg[i]) { continue; }
                s = seg[i].split('=');
                ret[s[0]] = s[1];
            }
            return ret;
        })(),
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
        hash: a.hash.replace('#', ''),
        path: a.pathname.replace(/^([^\/])/, '/$1'),
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
        segments: a.pathname.replace(/^\//, '').split('/')
        };
    }
</script>
