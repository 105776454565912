<div class="container" *ngIf="pais!=2">
    <div class="row">
        <div id ="hamburguer" class="col ham">
            <span class="menu_hamburger" onclick="openNav(event)">&#9776;</span>
        </div>
        <div class="col col-xl-2 col-lg-3 col-md-4 col-sm-4 total">

            <ul id="mySidenav" class="menu_lateral sidenav">
                <h4 id="tituloProducto" class="title" #tituloProducto>Créditos</h4>
                <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
                <li *ngIf="!showResumen" [class.activo-borde]="showHomeContent === 1"
                    [class.activo]="showHomeContent === 0 || showHomeContent === 1"
                    [class.inactivo]="showHomeContent !== 0 && showHomeContent !==1">
                    <a id="btn1" (click)="showHomeOption(1)" onclick="closeNav()">Adelanto<br> <strong>de Efectivo</strong></a>
                </li>
                <li *ngIf="showResumen" [class.activo-borde]="showHomeContent === 8"
                    [class.activo]="showHomeContent === 0 || showHomeContent === 8"
                    [class.inactivo]="showHomeContent !== 0 && showHomeContent !==8">
                    <a id="btn8" (click)="showHomeOption(8)" onclick="closeNav()">Adelanto<br> <strong>de Efectivo</strong></a>
                </li>
                <!--<li [class.activo-borde]="showHomeContent === 4"
                    [class.activo]="showHomeContent === 0 || showHomeContent === 4"
                    [class.inactivo]="showHomeContent !== 0 && showHomeContent !== 4">
                    <a id="btn2" (click)="showHomeOption(4)" onclick="closeNav()">Crédito<br> <strong>Personal</strong></a>
                </li>-->
                <!--<li [class.activo-borde]="showHomeContent === 2"
                    [class.activo]="showHomeContent === 0 || showHomeContent === 2"
                    [class.inactivo]="showHomeContent !== 0 && showHomeContent !==2">
                    <a id="btn2" (click)="showHomeOption(2)" onclick="closeNav()">Crédito<br> <strong>Personal</strong></a>
                </li>-->
                <li [class.activo-borde]="showHomeContent === 3"
                    [class.activo]="showHomeContent === 0 || showHomeContent === 3"
                    [class.inactivo]="showHomeContent !== 0 && showHomeContent !== 3">
                    <a id="btn9" (click)="showHomeOption(3)" onclick="closeNav()">Tarjeta <strong>Oro</strong> <br> <strong>Colaboradores</strong></a>
                </li>

                <li [class.activo-borde]="showHomeContent === 5"
                    [class.activo]="showHomeContent === 0 || showHomeContent === 5"
                    [class.inactivo]="showHomeContent !== 0 && showHomeContent !== 5">
                    <a id="btn11" (click)="showHomeOption(5)" onclick="closeNav()"><strong>Venta Empleado</strong></a>
                 </li>

                <li [class.activo-borde]="showHomeContent === 9"
                 [class.activo]="showHomeContent === 0 || showHomeContent === 9"
                 [class.inactivo]="showHomeContent !== 0 && showHomeContent !== 9">
                 <a id="btn12" (click)="showHomeOption(9)" onclick="closeNav()">Préstamo <strong>Personal GS</strong> </a>
                </li>

                <!----<li [class.inactivo]="showHomeContent !== 0" [class.activo]="showHomeContent === 0"><a id="btn3">Creditalika<br>Socio</a></li>
                <li [class.inactivo]="showHomeContent !== 0" [class.activo]="showHomeContent === 0"><a id="btn4">CrediAuto<br>Socio</a></li>
                <li [class.inactivo]="showHomeContent !== 0" [class.activo]="showHomeContent === 0"><a id="btn5">Vivienda<br>Socio</a></li>
                <li [class.inactivo]="showHomeContent !== 0" [class.activo]="showHomeContent === 0" class="final"><a id="btn6">Tarjeta<br>de Crédito</a></li>--->


                <!--   <li [class.activo-borde]="showHomeContent === 12"
                    [class.activo]="showHomeContent === 0 || showHomeContent === 12"
                    [class.inactivo]="showHomeContent !== 0 && showHomeContent !== 12">
                        <a id="btn14" (click)="showHomeOption(12)" onclick="closeNav()">Préstamo <strong>Auto GS</strong> </a>
                </li>-->
                <li [class.activo-borde]="showHomeContent === 7"
                    [class.activo]="showHomeContent === 0 || showHomeContent === 7"><a id="btn6"
                    [class.inactivo]="showHomeContent !== 0 && showHomeContent !==7"
                    (click)="showHomeOption(7)" onclick="closeNav()">Mis&nbsp;Créditos</a></li>
                        <li class="separador"></li>
                        <h4 id="tituloProducto" class="title" #tituloProducto>Otros Beneficios</h4>
                        <li [class.activo-borde]="showHomeContent === 4"
                        [class.activo]="showHomeContent === 0 || showHomeContent === 4"
                        [class.inactivo]="showHomeContent !== 0 && showHomeContent !== 4">
                        <a id="btn10" (click)="showHomeOption(4)" onclick="closeNav()">Divisas <strong>GS</strong></a>
                    </li>

                   <!-- <li [class.activo-borde]="showHomeContent === 11"
                        [class.activo]="showHomeContent === 0 || showHomeContent === 11"
                        [class.inactivo]="showHomeContent !== 0 && showHomeContent !== 11">
                        <a id="btn13" (click)="showHomeOption(11)" onclick="closeNav()">TPremia <strong>GS</strong></a>
                    </li>

                    <li [class.activo-borde]="showHomeContent === 13"
                    [class.activo]="showHomeContent === 0 || showHomeContent === 13"
                    [class.inactivo]="showHomeContent !== 0 && showHomeContent !== 13">
                        <a id="btn15" (click)="showHomeOption(13)" onclick="closeNav()">Inversión Activa <strong>Grupo Salinas</strong> </a>
                    </li>-->

                    <li [class.activo-borde]="showHomeContent === 16"
                    [class.activo]="showHomeContent === 0 || showHomeContent === 16"
                    [class.inactivo]="showHomeContent !== 0 && showHomeContent !== 16">
                        <a id="btn17" (click)="showHomeOption(16)" onclick="closeNav()">Presta <strong>Prenda</strong></a>
                    </li>


            </ul>
        </div>
        <div *ngIf="showHomeContent === 0" class="col col-xl-10 col-lg-9 col-md-8 col-sm-8 col-xs-12 responsive">
            <div class="bannerCreditos"></div>
            <h1 class="titleHome">¡Estamos contigo, cuando más nos necesitas!</h1>
            <h2 class="title1">Al ser parte de la familia de <strong>Grupo Salinas</strong> cuentas con diferentes <strong>Beneficios Financieros </strong>
                para que puedas <strong>cumplir tus sueños</strong>, lograr <strong>tus metas</strong> o cubrir algún imprevisto</h2>
        </div>
        <div *ngIf="showHomeContent === 1 && !showResumen" #contenidoAdE id="contenidoAdE"
            class="col col-xl-10 col-lg-9 col-md-8 col-sm-8 responsive">
            <app-adelanto-home [activoli]=activoli></app-adelanto-home>
        </div>
        <div *ngIf="showHomeContent === 8" id="resumen-page"
            class="col col-xl-10 col-lg-9 col-md-8 col-sm-8 responsive">
            <app-resumen (salir)="eventoSalir($event)"></app-resumen>
        </div>
        <!--<div *ngIf="showHomeContent === 2" #contendioPS id="contenidoPS"
            class="col col-xl-10 col-lg-9 col-md-8 col-sm-8 responsive">
            <app-prestamo [activoli]=activoli></app-prestamo>
        </div>-->
        <div *ngIf="showHomeContent === 3" #contendioTOR id="contenidoTOR"
            class="col col-xl-10 col-lg-9 col-md-8 col-sm-8 responsive">
            <app-tarjetaoro [activoli]=activoli></app-tarjetaoro>
        </div>
        <div *ngIf="showHomeContent === 4" #contendioDGS id="contendioDGS"
            class="col col-xl-10 col-lg-9 col-md-8 col-sm-8 responsive">
            <app-divisasgs [activoli]=activoli></app-divisasgs>
        </div>
        <div *ngIf="showHomeContent === 11" #contendioDGS id="contendioTP"
            class="col col-xl-10 col-lg-9 col-md-8 col-sm-8 responsive">
            <app-tpremia [activoli]=activoli></app-tpremia>
        </div>
        <div *ngIf="showHomeContent === 5" #contendioEKT id="contendioEKT"
            class="col col-xl-10 col-lg-9 col-md-8 col-sm-8 responsive">
            <app-beneficioekt [activoli]=activoli></app-beneficioekt>
        </div>
        <div *ngIf="showHomeContent === 9" #contendioCredBF id="contendioCBF"
            class="col col-xl-10 col-lg-9 col-md-8 col-sm-8 responsive">
            <app-creditobf [activoli]=activoli></app-creditobf>
        </div>
        <div *ngIf="showHomeContent === 7" class="col col-xl-10 col-lg-9 col-md-8 col-sm-8">
            <app-miscreditos></app-miscreditos>
        </div>
        <div *ngIf="showHomeContent === 12" #contendioCrediAuto id="contendioCrediAuto"
        class="col col-xl-10 col-lg-9 col-md-8 col-sm-8 responsive">
        <app-crediauto [activoli]=activoli></app-crediauto>
        </div>
       <!-- <div *ngIf="showHomeContent === 13" #contendioInversionActiva id="contendioInversionActiva"
        class="col col-xl-10 col-lg-9 col-md-8 col-sm-8 responsive">
        <app-inversion-activa [activoli]=activoli></app-inversion-activa>
        </div>-->

        <div *ngIf="showHomeContent === 16" #contendioInversionActiva id="contendioPrestaPrenda"
        class="col col-xl-10 col-lg-9 col-md-8 col-sm-8 responsive">
        <app-presta-prenda [activoli]=activoli></app-presta-prenda>
        </div>
    </div>
</div>

<div class="container" *ngIf="pais==2">
    <div class="row">
        <div id ="hamburguer" class="col ham">
            <span class="menu_hamburger" onclick="openNav(event)">&#9776;</span>
        </div>
        <div class="col col-xl-2 col-lg-3 col-md-4 col-sm-4 total">

            <ul id="mySidenav" class="menu_lateral sidenav">
                <h4 id="tituloProducto" class="title" #tituloProducto>Créditos</h4>
                <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
                <li [class.activo-borde]="showHomeContent === 14"
                    [class.activo]="showHomeContent === 0 || showHomeContent === 14"
                    [class.inactivo]="showHomeContent !== 0 && showHomeContent !== 14">
                    <a id="btn16" (click)="showHomeOption(14)" onclick="closeNav()"><strong>Venta Empleado</strong></a>
                 </li>
                </ul>
                </div>
                <div *ngIf="showHomeContent === 14" #contendioEKT id="contendioPG"
            class="col col-xl-10 col-lg-9 col-md-8 col-sm-8 responsive">
            <app-portal-guatemala [activoli]=activoli></app-portal-guatemala>
        </div>
                </div>

</div>


<script>
    if (self === top) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }

    function redireccionAlSitio() {
        try {
            var surl = self;
            if (validateURL(surl))
                top.location.href = surl;
            else {
                throw new InvalidURLException();
            }
        } catch (e) {
            if (e instanceof InvalidURLException)
                alert(e.message);
        }
    }

    function InvalidURLException() {
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";
        this.toString = function () {
            return this.message
        };
    }

    function validateURL(surl) {
        var url = parseURL(surl);
        var urlHostname = url.hostname.trim();

        if (urlHostname == '') {
            return true;
        } else {
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {
                return true;
            } else {
                return false;
            }
        }
    }

    function parseURL(url) {
        var a = document.createElement('a');
        a.href = url;
        return {
            source: url,
            protocol: a.protocol.replace(':', ''),
            hostname: a.hostname,
            host: a.host,
            port: a.port,
            query: a.search,
            params: (function () {
                var ret = {},
                    seg = a.search.replace(/^\?/, '').split('&'),
                    len = seg.length, i = 0, s;
                for (; i < len; i++) {
                    if (!seg[i]) { continue; }
                    s = seg[i].split('=');
                    ret[s[0]] = s[1];
                }
                return ret;
            })(),
            file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
            hash: a.hash.replace('#', ''),
            path: a.pathname.replace(/^([^\/])/, '/$1'),
            relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
            segments: a.pathname.replace(/^\//, '').split('/')
        };
    }
</script>
