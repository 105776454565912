import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HeaderService } from "../header.options";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import { MatDialog } from '@angular/material/dialog';
import { DialogOverviewMsg} from '../../../../shared/components/modales/modalMaterialMsg/dialog-overview-msg';
import { AdelantoModelPost } from "./AdelantoModelPost";

@Injectable({providedIn: 'root'})
export class ApiAdelantoService {

    constructor(
        private httpClient: HttpClient,
        private headers: HeaderService,
        private dialog: MatDialog

    ) {}


    getAdelanto(numeroEmpleado: string, idEstatus: number): Observable<any> {
        const parametros = new URLSearchParams();
        parametros.set('numeroEmpleado', numeroEmpleado);
        parametros.set('idEstatus', '' + idEstatus);
        return this.httpClient.get(environment.api_url_adelanto + '?' + parametros.toString(),
        {headers: this.headers.getHeaderOption(false)}
        ).pipe(
            catchError( e => {
                throw e;
              })
        );
    }

    postAdelanto(numeroEmpleado: string, idCotizador: string): Observable<any> {
      const adelantoModel = new AdelantoModelPost();
      adelantoModel.numeroEmpleado = numeroEmpleado;
      adelantoModel.idCotizador = idCotizador;
      return this.httpClient.post(environment.api_url_adelanto, adelantoModel,
        {headers: this.headers.postHeaderOption(false)}).pipe(
          catchError( e => {
              throw e;
            })
      );
    }

    getAdelantoPorSolicitud(idSolicitud: string): Observable<any> {
      console.log("idSolicitud: "+idSolicitud);
        return this.httpClient.get(environment.api_url_adelanto + '/' + idSolicitud ,
        {headers: this.headers.getHeaderOption(false)}
        ).pipe(
            catchError( e => {

                throw e;
              }));
    }

    getAdelantoPorSolicitudPrestamo(idSolicitud: string, idProducto : number): Observable<any> {
      const parametros = new URLSearchParams();
      parametros.set('idProducto',''+ idProducto);
      return this.httpClient.get(environment.api_url_adelanto + '/' + idSolicitud + '?' + parametros.toString(),
      {headers: this.headers.getHeaderOption(false)}
      ).pipe(
          catchError( e => {

              throw e;
            }));
  }


}
