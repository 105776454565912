import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JwtService } from '../services/jwt.service';
import { UtileriaEnum } from '../services/utileria/utileria.enum';
import { DialogOverviewMsg } from '../../shared/components/modales/modalMaterialMsg/dialog-overview-msg'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService,
    private router: Router,
    private dialog: MatDialog) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const re = /notificaciones/gi;

    const headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    const token = this.jwtService.getToken();

    if (token) {
      headersConfig['Authorization'] = `Token ${token}`;
    }
    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(req).pipe(
      catchError(e => {
        if (e.status === 401) {
          // En caso de que no contar con autorización se borrarán los datos
          // del sessionstorage y se redireccionará al Home
          let idToken = sessionStorage.getItem('id_token');
    const parametros = new URLSearchParams();
        parametros.set('id_token_hint', idToken);
        parametros.set('post_logout_redirect_uri', environment.redirectLogoutOkta);
        parametros.set('state',environment.okta_state);
        window.sessionStorage ? sessionStorage.clear() : console.log('Sin variables para borrar');

        this.router.navigate(['/externalRedirect', { externalUrl: environment.okta_url_logout+ '?' + parametros.toString() }], {
          skipLocationChange: true,
      });
        }
        if (e.status === 502 || e.status === 503 || e.status === 504) {
          this.dialog.closeAll();
          this.openDialogMsg(e.error.mensaje);
        }

        if (e.status === 0 && req.url.search(re) === -1) {
          this.dialog.closeAll();
          this.openDialogMsg(UtileriaEnum.ERROR_FATAL);
        }
        return throwError(e);
      })
    );
  }

  openDialogMsg(msg: string): void {
    this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true,
        autoFocus: false
      }
    });
  }
}
