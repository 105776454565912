export class CotizadorPrestamoPost {
    numeroEmpleado: string;
    montoSolicitado: number;
    montoPago : number;
    montoMaximoOfertado: number;
    idSolicitud: string;
    idProducto : number;
    idPlazo: number;
    capacidadPago: number;



}
