import { Component,Host, OnInit,Input } from '@angular/core';
import { HomeComponent } from '../../home.component';
import { AppComponent } from 'src/app/app.component';
import { ArchivoService, Errors, UserService } from 'src/app/core';
import { ApiValidacionesService } from 'src/app/core/services/credito/api.adelanto.validaciones/api.validaciones.service';
import { HomeService } from 'src/app/core/services/home.service';
import { ApiDocumentosService } from 'src/app/core/services/credito/api.documentos/api.documentos';
import { TokenApiService } from 'src/app/core/services/token.service';
import { ValidacionErrors } from 'src/app/core/services/utileria/validacion.error';
import { Router } from '@angular/router';
import { ApiConsentimientoService } from 'src/app/core/services/credito/api.consentimiento/api.consentimiento.service';
import { ApiAdelantoService } from 'src/app/core/services/credito/api.adelanto/api.adelanto';
import { MatDialog } from '@angular/material/dialog';
import { TipoProducto } from 'src/app/core/services/utileria/utileria.enum';
import { DialogOverviewMsg } from 'src/app/shared/components/modales/modalMaterialMsg/dialog-overview-msg';


@Component({
  selector: 'app-creditobf',
  templateUrl: './creditobf.home.html',
  styleUrls: ['./creditobf.home.scss']
})
export class CreditoBFComponent {

  @Input() activoli;

  parentHome: HomeComponent;
  modalVideo = false;

  constructor(
 parentHome: HomeComponent
  ) {
    this.parentHome = parentHome;
  }

  validacionCP() {
    this.parentHome.validacionAvisoPrivacidad(2);
  }



}


