<h2 class="title">4. Selecciona el monto de tu {{txtProducto}}.</h2>
<p *ngIf="producto===1">Ingresa el monto de tu {{txtProducto}} en múltiplos de <strong>$50.00</strong> pesos:</p>
<p *ngIf="producto===2">Ingresa el monto de tu {{txtProducto}} en múltiplos de <strong>$1,000.00</strong> pesos:</p>
<br>
<div class="container">
      <div class="row justify-content-md-center">
            <div class="col-8 conf">
                  <input prefix="$ "mask="comma_separator.0" type="text" class="form-control text-center" name="txtMonto" (change)="cambiaValor()" id="monto-ade"
                        [(ngModel)]="valorInput" maxlength="{{maxDigitos}}">
            </div>
      </div>
</div>
<br>
<div class="row">
      <div class="col-12">
            <ngx-slider class="slid" [(value)]="value" [options]="options" (userChangeEnd)="onUserChangeEnd($event)" (userChange)="onUserChange($event)"></ngx-slider>
      </div>
</div>
<div class="form-group row">
      <p class="col-6 cantidad-min text-left">{{min|milesentero}}</p>
      <p class="col-6 cantidad-max text-right">{{max|milesentero}}</p>
      <label for="formGroupExampleInput" class="col-6 text-left min">Mín.</label>
      <label for="formGroupExampleInput" class="col-6 text-right max">Máx.</label>
</div>

  <form *ngIf="producto===2" >
    <div  class="container test " *ngFor="let item of listaPlazos; index as i" id="plazo" >
      <div  class="row justify-content-md-center radio"  >
        <div class="col-8 conf border border-success radio-wrapper "  >
          <label><input type="radio" name="item" id="radioInput" [value]="item"   [(ngModel)]="plazosModel"  > {{item.idPlazo}} pagos {{tipoNomina}} de <strong>{{item.pagoPlazo|conversor}}</strong> </label>
        </div>
        <br>
        <br>
      </div>
      <br>
      <br>
    </div>
    <br>
    <br>
  </form>
<hr *ngIf="producto===1" class="slide">
<br>
<h3 *ngIf="producto===1" class="col-12 subtitle text-left">Detalle de la operación:</h3>
<br>
<div  *ngIf="producto===1" class="row">
      <table class="table table-striped two">
            <tbody>
                  <tr>
                        <td class="col-8"><strong>{{producto===1?'Monto solicitado del adelanto:':'Monto del Credito:'}}</strong>
                        </td>
                        <td class="col-4 text-right"><strong>{{producto===1?(montoDeposito|conversor):(montoDeposito|conversor)}}</strong>
                        </td>
                  </tr>
                  <tr>
                        <td class="col-8">{{producto===1?'Comisión por disposición '+comisionInfo+'% total:':'*Tasa fija anual:'}}</td>
                        <td class="col-4 text-right">{{producto===1?(comision|conversor):tasa+'%'}}</td>
                  </tr>
                  <tr *ngIf="producto===1">
                        <td class="col-8">IVA de la comisión*:</td>
                        <td class="col-4 text-right">{{(iva|conversor)}}</td>
                  </tr>
                  <tr>
                        <td class="col-8"><strong>{{producto===1?'Monto total a pagar del Adelanto:':'Fecha del primer pago:'}}</strong></td>
                        <td class="col-4 text-right"><strong>{{producto===1?(montoTotal|conversor):fecha}}</strong></td>
                  </tr>
            </tbody>
      </table>
</div>
<p class="col-12 text-left monto" *ngIf="producto===1">Nota: Monto máximo calculado considerando el 30% de tu siguiente pago salarial.</p>
<p class="col-12 text-left monto" *ngIf="producto===1">*Todas las comisiones se cobrarán más el Impuesto al Valor Agregado (IVA).</p>
<!--<p class="col-12 text-left monto" *ngIf="producto===2">*La tasa será fija durante el plazo del crédito personal.</p>-->
<br><br><br><br>
<div class="col pad btns_foot">
      <button class="btn atras" name="btnAtras" (click)="routeFunction('/referenciasfamiliares')">Atrás</button>
      <button class="btn cancelar" name="btnCancelar" (click)="openDialogCancelacionesMensaje()">Cancelar</button>
      <button class="btn all" name="btnContinuar" (click)="guardarMontos()">Continuar</button>
</div>
<script>
      if ( self === top ) {
            document.documentElement.style.display = 'block';
      } else {
            redireccionAlSitio();
      }

      function redireccionAlSitio() {
            try {
                  var surl = self;
                  if (validateURL(surl))
                  top.location.href = surl;
                  else {
                  throw new InvalidURLException();
                  }
            } catch (e) {
                  if (e instanceof InvalidURLException)
                  alert(e.message);
            }
      }

      function InvalidURLException() {
            this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";
            this.toString = function () {
                  return this.message
            };
      }

      function validateURL(surl) {
            var url = parseURL(surl);
            var urlHostname = url.hostname.trim();

            if (urlHostname == '') {
                  return true;
            } else {
                  if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {
                  return true;
                  } else {
                  return false;
                  }
            }
      }

      function parseURL(url) {
      var a = document.createElement('a');
      a.href = url;
      return {
            source: url,
            protocol: a.protocol.replace(':', ''),
            hostname: a.hostname,
            host: a.host,
            port: a.port,
            query: a.search,
            params: (function () {
                  var ret = {},
                  seg = a.search.replace(/^\?/, '').split('&'),
                  len = seg.length, i = 0, s;
                  for (; i < len; i++) {
                  if (!seg[i]) { continue; }
                  s = seg[i].split('=');
                  ret[s[0]] = s[1];
                  }
                  return ret;
            })(),
            file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
            hash: a.hash.replace('#', ''),
            path: a.pathname.replace(/^([^\/])/, '/$1'),
            relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
            segments: a.pathname.replace(/^\//, '').split('/')
            };
      }
</script>
